import { useEffect, useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import { useResizeListener } from 'primereact/hooks';
import { classNames } from 'primereact/utils';
import { CalendarDaysIcon, ListBulletIcon } from '@heroicons/react/24/outline';
import useSWR from 'swr';
import { SelectItem, SelectList } from "../../components/SelectList";
import { FundData, IndexData, downloadPriceData } from '../../lib/prices/download';
import { FundInfo } from '../../lib/query-funds';
import { CHART_COLORS, transparentize } from '../../lib/chart_utils';
import { when } from "../../lib/react-helper";
import { queryFundPrices } from '../../lib/query-fund-prices';
import { DailyValue, queryIndices } from '../../lib/query-indices';
import { FundChartDataset, FundMultiChart, FundMultiChartProps } from './FundMultiChart';
import { FadeTransition } from '../../components/FadeTransition';
import './styles.scss';

const dateRanges = [ '3개월', '6개월', '1년', '3년', '5년', '전체' ].map(v => ({id: v, label: v, data: null}));

type ProfitCompetitorId = 'KOSPI' | 'KOSDAQ' | 'KPI200' | 'AUTOSTOCK_PART';

type Y1Type = '기준가' | '수익률';
const Y1Types = ['기준가', '수익률'].map(v => ({id: v as Y1Type, label: v, data: null}));

type Y2Type = '원본금액' | '자산구성' | '자산구성비중' | '없음';
const Y2Types = ['원본금액', '자산구성', '자산구성비중', '없음'].map(v => ({id: v as Y2Type, label: v, data: null}));

interface FundDetailViewProps {
	fund: FundInfo;
	className?: string;
}
export function FundDetailView({fund, ...props}: FundDetailViewProps) {
	const [dateRange, setDateRange] = useState<string>('3개월');
	const [profitCompetitorSelection, setProfitCompetitorSelection] = useState<ProfitCompetitorId[]>([]);
	const [downloading, setDownloading] = useState<boolean>(false);
	const [showChartOptions, setShowChartOptions] = useState<boolean>(false);
	const [y1Type, setY1Type] = useState<Y1Type>(Y1Types[0].id);
	const [y2Type, setY2Type] = useState<Y2Type>(Y2Types[0].id);
	const [screenSize, setScreenSize] = useState<{width: number, height: number}>({width: window.innerWidth, height: window.innerHeight});
	const showChartOptionsAlways = !isPhoneSized(screenSize);

	const [bindWindowResizeListener, unbindWindowResizeListener] = useResizeListener({
		listener: (event) => {
			console.log('resize', event);
			setScreenSize({ width: window.innerWidth, height: window.innerHeight });
		}
	});

	useEffect(() => {
		bindWindowResizeListener();
		return unbindWindowResizeListener;
	}, []);

	const resolvedRange = resolveDateRange(dateRange);
	const begin_date = resolvedRange.begin?.format_ymd() || fund?.list_date;
	const end_date = (resolvedRange.end || new Date()).format_ymd()
	
	const { fundData } = useFundData(fund || null, begin_date || null, end_date);

	const indexProfitSelection = profitCompetitorSelection.filter(id => id !== 'AUTOSTOCK_PART');
	const { indexData: indexData0 } = useIndexData(y1Type === "수익률" && indexProfitSelection[0] || null, begin_date || null, end_date);
	const { indexData: indexData1 } = useIndexData(y1Type === "수익률" && indexProfitSelection[1] || null, begin_date || null, end_date);
	const { indexData: indexData2 } = useIndexData(y1Type === "수익률" && indexProfitSelection[2] || null, begin_date || null, end_date);
	const { indexData: cd91 } = useIndexData('CD91', begin_date || null, end_date);

	const dates = useMemo<string[]>(() => {
		return fundData?.prices.map(p => p.date) || [];
	}, [fundData]);

	const {autostockPart: autostockProfitData, bondPart: bondProfitData} = useMemo(() => {
		if (fundData?.fund.code === 'hdcatst1' && fundData && cd91) {
			return createFundPartialProfitData(fundData, cd91);
		}
	}, [fundData, cd91]) || {};

	const {
		datasetFundPrice, datasetFundProfit, datasetFundInputSize,
		datasetFundStockSize, datasetFundBondSize, datasetFundLiquidSize,
		datasetFundStockRatio, datasetFundBondRatio, datasetFundLiquidRatio,
	} = useMemo<Partial<FundDatasetGroup>>(() => {
		if (fundData) {
			return createFundDatasetGroup(fundData, dates);
		}
		return {};
	}, [fundData, dates]);

	const datasetProfitCompetitor0 = useMemo<FundChartDataset | undefined>(() => {
		if (indexData0) {
			return createDatasetIndexProfit(indexData0, dates);
		}
	}, [indexData0, dates]);

	const datasetProfitCompetitor1 = useMemo<FundChartDataset | undefined>(() => {
		if (indexData1) {
			return createDatasetIndexProfit(indexData1, dates);
		}
	}, [indexData1, dates]);

	const datasetProfitCompetitor2 = useMemo<FundChartDataset | undefined>(() => {
		if (indexData2) {
			return createDatasetIndexProfit(indexData2, dates);
		}
	}, [indexData2, dates]);

	const datasetAutostockProfit = useMemo<FundChartDataset | undefined>(() => {
		if (autostockProfitData) {
			return createDatasetIndexData(autostockProfitData, dates);
		}
	}, [autostockProfitData, dates]);

	const datasetBondProfit = useMemo<FundChartDataset | undefined>(() => {
		if (bondProfitData) {
			return createDatasetIndexData(bondProfitData, dates);
		}
	}, [bondProfitData, dates]);

	useEffect(() => {
		if (profitCompetitorSelection.includes('AUTOSTOCK_PART')) {
			setProfitCompetitorSelection(profitCompetitorSelection.filter(id => id !== 'AUTOSTOCK_PART'));
		}
	}, [fund.code]);

	const y1 = useMemo<FundMultiChartProps['y1']>(() => {
		const datasets: FundChartDataset[] = [];
		if (y1Type === '기준가' && datasetFundPrice) {
			datasets.push(datasetFundPrice);
		}
		if (y1Type === '수익률' && datasetFundProfit) {
			datasets.push(datasetFundProfit);

			if (indexProfitSelection[0] && datasetProfitCompetitor0) {
				datasets.push(datasetProfitCompetitor0);
			}
			if (indexProfitSelection[1] && datasetProfitCompetitor1) {
				datasets.push(datasetProfitCompetitor1);
			}
			if (indexProfitSelection[2] && datasetProfitCompetitor2) {
				datasets.push(datasetProfitCompetitor2);
			}
			if (profitCompetitorSelection.includes('AUTOSTOCK_PART') && datasetBondProfit) {
				datasets.push(datasetBondProfit);
			}
			if (profitCompetitorSelection.includes('AUTOSTOCK_PART') && datasetAutostockProfit) {
				datasets.push(datasetAutostockProfit);
			}
		}
		return { type: y1Type, datasets };
	}, [y1Type, profitCompetitorSelection, datasetFundPrice, datasetFundPrice, datasetProfitCompetitor0, datasetProfitCompetitor1, datasetProfitCompetitor2, datasetAutostockProfit]);

	const y2 = useMemo<FundMultiChartProps['y2']>(() => {
		if (y2Type !== '없음') {
			const datasets: FundChartDataset[] = [];
			if (y2Type === '원본금액' && datasetFundInputSize) {
				datasets.push(datasetFundInputSize);
			}
			if (y2Type === '자산구성') {
				if (datasetFundStockSize) {
					datasets.push(datasetFundStockSize);
				}
				if (datasetFundBondSize) {
					datasets.push(datasetFundBondSize);
				}
				if (datasetFundLiquidSize) {
					datasets.push(datasetFundLiquidSize);
				}
			}
			if (y2Type === '자산구성비중') {
				if (datasetFundStockRatio) {
					datasets.push(datasetFundStockRatio);
				}
				if (datasetFundBondRatio) {
					datasets.push(datasetFundBondRatio);
				}
				if (datasetFundLiquidRatio) {
					datasets.push(datasetFundLiquidRatio);
				}
			}
			return { type: y2Type, datasets };
		}
	}, [y2Type, datasetFundInputSize, datasetFundStockSize, datasetFundBondSize, datasetFundLiquidSize, datasetFundStockRatio, datasetFundBondRatio, datasetFundLiquidRatio]);

	const lastFundPrice = fundData?.prices[fundData.prices.length - 1];

	const onDownload = async (fundData: FundData) => {
		setDownloading(true);

		const additionalData = [
			cd91,
			indexData0,
			indexData1,
			indexData2,
			profitCompetitorSelection.includes('AUTOSTOCK_PART') && bondProfitData,
			profitCompetitorSelection.includes('AUTOSTOCK_PART') && autostockProfitData,
		].filter(i => !!i) as IndexData[];
		downloadPriceData(fundData, additionalData)
			.finally(() => setDownloading(false));
	}

	return (
		<div className={classNames('fund-detail-view', props.className)}>
			<div className='flex flex-column md:flex-row md:gap-4 xl:gap-8'>
				<div className='px-4'>
					<h3>{fund.name}</h3>
					<div className='max-w-30rem border-1 border-200 border-round-2xl surface-100 p-3 flex justify-content-evenly'>
						<div>
							<label className='mb-2'>기준가 (보정)</label>
							{lastFundPrice &&
								<div>
									<span>{lastFundPrice.price?.toLocaleString()}</span>
									<span className='ml-2 text-400'>({Date.from_ymd(lastFundPrice.date).format_ymd('-')})</span>
								</div>
							}
							{!lastFundPrice &&
								<div>...</div>
							}
						</div>
						<div className='border-left-1 border-200 mx-3'></div>
						<div>
							<label className='mb-2'>설정원본</label> 
							{lastFundPrice && lastFundPrice.input_size?.toLocaleString() || '(N/A)'}
							{!lastFundPrice && '...'}
						</div>
					</div>
					<div className='flex mt-5'>
						<DateRangeOptionsView
							selection={dateRange}
							onChangeSelection={setDateRange}
						/>
					</div>
				</div>
				{!showChartOptions && !showChartOptionsAlways &&
					<div>
						<Button label="차트옵션" icon="pi pi-cog" severity='secondary' text onClick={() => setShowChartOptions(true)} />
					</div>
				}
				<FadeTransition show={showChartOptions || showChartOptionsAlways} from='up' duration={100}>
					<div className='flex px-4 my-3 chart-option-box'>
						<div className='inline-block w-16rem xl:w-18rem'>
							<Y1TypeOptionsView
								selection={y1Type}
								onChangeSelection={setY1Type}
							/>
							<FadeTransition show={y1Type === '수익률'} from='right' duration={100}>
								<ProfitCompetitorOptionsView
									fund={fund}
									className='ml-4'
									selection={profitCompetitorSelection}
									onChangeSelection={setProfitCompetitorSelection}
								/>
							</FadeTransition>
						</div>
						<Y2TypeOptionsView
							className='inline-block w-16rem xl:w-18rem'
							selection={y2Type}
							onChangeSelection={setY2Type}
						/>
						<FadeTransition show={showChartOptionsAlways && profitCompetitorSelection.includes('AUTOSTOCK_PART')} from='right' duration={100}>
							<div className='inline-block w-24rem xl:w-28rem description-box'>
								<p>
									펀드 수익률이 50%의 채권 수익률과 50%의 오토스탁 운용 수익률로 구성된다고 가정하여
									채권 부분 수익률과 오토스탁 부분 수익률을 산출합니다.
								</p>
								<p>
									<b>채권 부분 수익률</b>은 CD금리를 기준으로 계산하며<br/>
									<b>오토스탁 부분 수익률</b>은 펀드 수익률중 채권 부분 수익률을 제외하여 계산합니다.
								</p>
							</div>
						</FadeTransition>
						{!showChartOptionsAlways &&
							<div className="chart-option-closer">
								<Button icon="pi pi-times-circle" severity='secondary' text onClick={() => setShowChartOptions(false)} />
							</div>
						}
					</div>
				</FadeTransition>
			</div>
			<div className='md:mt-5 fund-chart-wrapper'>
				<FundMultiChart className="h-full" dates={dates} y1={y1} y2={y2} />
			</div>
			{when(fundData,
				<div className="mt-4">
					<Button label="다운로드" icon="pi pi-download"
						disabled={downloading}
						onClick={() => onDownload(fundData!)}
					/>
				</div>
			)}
		</div>
	);
}

interface DateRangeOptionsViewProps {
	selection: string;
	onChangeSelection: (dateRange: string) => void;
	className?: string;
}
function DateRangeOptionsView({selection, ...props}: DateRangeOptionsViewProps) {
	return (
		<div className={props.className}>
			<div className="flex justify-content-between">
				<h3 className="my-2 text-black-alpha-70">
					<CalendarDaysIcon className="mr-2" style={{width: '1.2em', height: '1.2em', verticalAlign: 'bottom'}}/>
					기간
				</h3>
			</div>
			<SelectList<string> mode="single" className="my-2" horizontal showCheckIcon={false}
				selection={[selection]} list={dateRanges}
				onChange={e => props.onChangeSelection(e.selection[0])} />
		</div>
	);
}

interface Y1TypeOptionsViewProps {
	selection: Y1Type;
	onChangeSelection: (type: Y1Type) => void;
	className?: string;
}
function Y1TypeOptionsView({selection, ...props}: Y1TypeOptionsViewProps) {
	return (
		<div className={props.className}>
			<div className="flex justify-content-between">
				<h3 className="my-2 text-black-alpha-70">
					<ListBulletIcon className="mr-2" style={{width: '1.2em', height: '1.2em', verticalAlign: 'bottom'}}/>
					차트옵션 (왼쪽Y축)
				</h3>
			</div>
			<SelectList<Y1Type> mode="single" appearance="fadeout" className="my-2"
				selection={[selection]} list={Y1Types}
				onChange={e => props.onChangeSelection(e.selection[0])} />
		</div>
	);
}

interface Y2TypeOptionsViewProps {
	selection: Y2Type;
	onChangeSelection: (type: Y2Type) => void;
	className?: string;
}
function Y2TypeOptionsView({selection, ...props}: Y2TypeOptionsViewProps) {
	return (
		<div className={props.className}>
			<div className="flex justify-content-between">
				<h3 className="my-2 text-black-alpha-70">
					<ListBulletIcon className="mr-2" style={{width: '1.2em', height: '1.2em', verticalAlign: 'bottom'}}/>
					차트옵션 (오른쪽Y축)
				</h3>
			</div>
			<SelectList<Y2Type> mode="single" appearance="fadeout" className="my-2"
				selection={[selection]} list={Y2Types}
				onChange={e => props.onChangeSelection(e.selection[0])} />
		</div>
	);
}

interface ProfitCompetitorOptionsViewProps {
	fund: FundInfo;
	selection: ProfitCompetitorId[];
	onChangeSelection: (selection: ProfitCompetitorId[]) => void;
	className?: string;
}
function ProfitCompetitorOptionsView({fund, selection, ...props}: ProfitCompetitorOptionsViewProps) {
	const options = useMemo(() => {
		const competitors: SelectItem<ProfitCompetitorId>[] = [
			{ id: 'KOSPI', label: '코스피 지수', data: null },
			{ id: 'KOSDAQ', label: '코스닥 지수', data: null },
			{ id: 'KPI200', label: '코스피200 지수', data: null },
		];
		if (fund.code === 'hdcatst1') {
			competitors.push({ id: 'AUTOSTOCK_PART', label: '부분 수익률', data: null });
		}
		return competitors;
	}, [fund.code]);

	return (
		<div className={props.className}>
			<SelectList<ProfitCompetitorId> mode="multiple" appearance="fadeout" className="my-2"
				selection={selection} list={options}
				onChange={e => props.onChangeSelection(e.selection)} />
		</div>
	);
}

type DateRange = {begin?: Date, end?: Date, label: string};
function resolveDateRange(label: string): DateRange {
	const end = new Date();
	const begin = getBeginDate(label, end);
	return {begin, end, label};
}

function getBeginDate(label: string, end_date: Date): Date | undefined {
	switch (label) {
		case '3개월':
			return new Date(end_date).add_months(-3);
		case '6개월':
			return new Date(end_date).add_months(-6);
		case '1년':
			return new Date(end_date).add_years(-1);
		case '3년':
			return new Date(end_date).add_years(-3);
		case '5년':
			return new Date(end_date).add_years(-5);
		case '10년':
			return new Date(end_date).add_years(-10);
		case '전체':
			return undefined;
		default:
			return new Date(end_date).add_months(-3);
	}
}

function useFundData(fund: FundInfo | null, begin_date: string | null, end_date: string) {
	const params = fund && begin_date && { code: fund!.code, begin_date, end_date };
	const fetcher = async ({params}: any) => {
		const prices = await queryFundPrices(params);
		const fundData: FundData = {
			type: 'fund',
			fund: fund!,
			days: {begin: params.begin_date, end: params.end_date},
			prices
		};
		return fundData;
	};
	const { data, error, isLoading } = useSWR(params && {url: '/data/fund/daily', params }, fetcher, {
		dedupingInterval: 1000 * 60 * 10, // 10분
		focusThrottleInterval: 1000 * 60 * 10, // 10분
	});
	return {
		fundData: data,
		isLoading,
		isError: error
	}
}

function useIndexData(code: string | null, begin_date: string | null, end_date: string) {
	const params = code && begin_date && { code, start_date: begin_date, end_date, type: 'base' };
	const fetcher = async ({params}: {params: Parameters<typeof queryIndices>[0]}) => {
		const dailyValues = await queryIndices(params);
		if (code === 'CD91') {
			return {
				type: 'index',
				code,
				name: 'CD금리(91일)',
				color: '#000000',
				dailyValues,
				unit: '1/100',
				style: { format: 'percent' },
			} as IndexData;
		} else {
			return constructIndexData(params.code, dailyValues);
		}
	};
	const { data, error, isLoading } = useSWR(params && {url: '/data/index/daily', params }, fetcher, {
		dedupingInterval: 1000 * 60 * 10, // 10분
		focusThrottleInterval: 1000 * 60 * 10, // 10분
	});
	return {
		indexData: data,
		isLoading,
		isError: error
	}
}

const indices = [
	{ code: 'KOSPI', name: 'KOSPI', color: CHART_COLORS.blue },
	{ code: 'KOSDAQ', name: 'KOSDAQ', color: CHART_COLORS.green },
	{ code: 'KPI200', name: 'KPI200', color: CHART_COLORS.orange},
];

function constructIndexData(code: string, dailyValues: DailyValue[]): IndexData {
	const i = indices.find(i => i.code === code);
	if (!i) {
		throw new Error(`Unknown index code: ${code}`);
	}

	return {
		type: 'index',
		code,
		name: i.name,
		color: i.color,
		dailyValues,
	}
}

interface FundDatasetGroup {
	datasetFundPrice: FundChartDataset;
	datasetFundProfit: FundChartDataset;
	datasetFundInputSize: FundChartDataset;
	datasetFundStockSize: FundChartDataset;
	datasetFundBondSize: FundChartDataset;
	datasetFundLiquidSize: FundChartDataset;
	datasetFundStockRatio: FundChartDataset;
	datasetFundBondRatio: FundChartDataset;
	datasetFundLiquidRatio: FundChartDataset;
}
function createFundDatasetGroup(fundData: FundData, dates: string[]): FundDatasetGroup {
	const firstPrice = fundData.prices.find(p => p.price != null)?.price;
	const prices = dates.map(d => fundData?.prices.find(p => p.date === d)?.price ?? null);
	const profits = prices.map(p => p ? ((p - firstPrice!) / firstPrice!)*100 : null);
	const inputSizes = dates.map(d => fundData?.prices.find(p => p.date === d)?.input_size ?? null);
	const stockSizes = dates.map(d => fundData?.prices.find(p => p.date === d)?.stock_size ?? null);
	const bondSizes = dates.map(d => fundData?.prices.find(p => p.date === d)?.bond_size ?? null);
	const liquidSizes = dates.map(d => fundData?.prices.find(p => p.date === d)?.liquid_size ?? null);
	const totalSizes = dates.map(d => {
		const p = fundData?.prices.find(p => p.date === d);
		return (p?.stock_size || 0) + (p?.bond_size || 0) + (p?.liquid_size || 0);
	});
	const stockRatios = stockSizes.map((size, i) => {
		const total = totalSizes[i];
		return total ? ((size || 0) / total * 100) : null;
	});
	const bondRatios = bondSizes.map((size, i) => {
		const total = totalSizes[i];
		return total ? ((size || 0) / total * 100) : null;
	});
	const liquidRatios = liquidSizes.map((size, i) => {
		const total = totalSizes[i];
		return total ? ((size || 0) / total * 100) : null;
	});

	return {
		datasetFundPrice: {
			type: 'line',
			label: '펀드기준가',
			data: prices,
			borderColor: CHART_COLORS.red,
			backgroundColor: transparentize(CHART_COLORS.red, 0.5),
			normalized: true,
		},
		datasetFundProfit: {
			type: 'line',
			label: '펀드수익률',
			data: profits,
			tooltipData: prices.map(p => {
				const profit = p ? ((p - firstPrice!) / firstPrice!)*100 : null;
				return profit ? profit.toFixed(1) + '% (' + p!.toLocaleString() + ')' : 'N/A';
			}),
			borderColor: CHART_COLORS.red,
			backgroundColor: transparentize(CHART_COLORS.red, 0.5),
			normalized: true,
		},
		datasetFundInputSize: {
			type: 'bar',
			label: '설정원본',
			data: inputSizes,
			borderWidth: 0,
			borderColor: CHART_COLORS.orange,
			backgroundColor: transparentize(CHART_COLORS.orange, 0.5),
			normalized: true,
		},
		datasetFundStockSize: {
			type: 'bar',
			label: '주식자산',
			data: stockSizes,
			borderWidth: 0,
			borderColor: CHART_COLORS.red,
			backgroundColor: transparentize(CHART_COLORS.red, 0.2),
			normalized: true,
			stack: 'assets',

		},
		datasetFundBondSize: {
			type: 'bar',
			label: '채권자산',
			data: bondSizes,
			borderWidth: 0,
			borderColor: CHART_COLORS.green,
			backgroundColor: transparentize(CHART_COLORS.green, 0.5),
			normalized: true,
			stack: 'assets',
		},
		datasetFundLiquidSize: {
			type: 'bar',
			label: '유동자산',
			data: liquidSizes,
			borderWidth: 0,
			borderColor: CHART_COLORS.yellow,
			backgroundColor: transparentize(CHART_COLORS.yellow, 0.5),
			normalized: true,
			stack: 'assets',
		},
		datasetFundStockRatio: {
			type: 'line',
			label: '주식자산비중',
			data: stockRatios,
			tooltipData: stockRatios.map(d => d ? (d.toFixed(1) + '%') : 'N/A'),
			borderWidth: 0,
			borderColor: CHART_COLORS.red,
			backgroundColor: transparentize(CHART_COLORS.red, 0.7),
			normalized: true,
			stack: 'assets',
			fill: true,
			stepped: true,
			animation: false,
		},
		datasetFundBondRatio: {
			type: 'line',
			label: '채권자산비중',
			data: bondRatios,
			tooltipData: bondRatios.map(d => d ? (d.toFixed(1) + '%') : 'N/A'),
			borderWidth: 0,
			borderColor: CHART_COLORS.green,
			backgroundColor: transparentize(CHART_COLORS.green, 0.7),
			normalized: true,
			stack: 'assets',
			fill: true,
			stepped: true,
			animation: false,
		},
		datasetFundLiquidRatio: {
			type: 'line',
			label: '유동자산비중',
			data: liquidRatios,
			tooltipData: liquidRatios.map(d => d ? (d.toFixed(1) + '%') : 'N/A'),
			borderWidth: 0,
			borderColor: CHART_COLORS.yellow,
			backgroundColor: transparentize(CHART_COLORS.yellow, 0.7),
			normalized: true,
			stack: 'assets',
			fill: true,
			stepped: true,
			animation: false,
		},
	};
}

function createDatasetIndexProfit(indexData: IndexData, dates: string[]): FundChartDataset {
	const firstPrice = indexData.dailyValues.find(p => p.value != null)?.value;
	const data = dates.map(d => indexData.dailyValues.find(p => p.date === d)?.value ?? null);
	return {
		type: 'line',
		label: indexData.name,
		data: data.map(p => p ? ((p - firstPrice!) / firstPrice!)*100 : null),
		tooltipData: data,
		borderColor: indexData.color,
		backgroundColor: transparentize(indexData.color, 0.5),
		normalized: true,
	};
}

function createDatasetIndexData(indexData: IndexData, dates: string[]): FundChartDataset {
	const data = dates.map(d => indexData.dailyValues.find(p => p.date === d)?.value ?? null);
	const tooltipData = data.map(p => {
		if (p == null) return 'N/A';
		if (indexData.style?.format === 'percent') {
			return p.toFixed(1) + '%';
		} else {
			return p.toLocaleString();
		}
	});
	return {
		type: 'line',
		label: indexData.name,
		data,
		tooltipData,
		borderColor: indexData.color,
		borderWidth: indexData.style?.borderWidth,
		borderDash: indexData.style?.borderDash,
		backgroundColor: transparentize(indexData.color, 0.5),
		normalized: true,
	};
}

function createFundPartialProfitData(fundData: FundData, cd91: IndexData): {autostockPart: IndexData, bondPart: IndexData} {
	const bondRatio = 0.5;
	const dates = fundData?.prices.map(p => p.date) || [];
	const firstPrice = fundData.prices.find(p => p.price != null)?.price;

	const dma = new DailyMovingAverage(Date.from_ymd(dates[0]));
	const bondDailyValues: DailyValue[] = [];
	const autostockDailyValues: DailyValue[] = [];
	fundData.prices.forEach(({date, price}) => {
		const fundProfit = price ? ((price - firstPrice!) / firstPrice!)*100 : 0;
		const cd91Price = cd91.dailyValues.find(p => p.date === date)?.value ?? null;
		const {average, days} = dma.add(Date.from_ymd(date), cd91Price);
		const bondProfit = average * days / 365;
		bondDailyValues.push({ date: date, value: bondProfit});

		const autostockProfit = (fundProfit - bondProfit * bondRatio) / (1 - bondRatio);
		autostockDailyValues.push({ date: date, value: autostockProfit });
	});

	const autostockPart: IndexData = {
		type: 'etc',
		code: 'AUTOSTOCK_PART',
		name: '오토스탁 부분 수익률',
		color: CHART_COLORS.darkred,
		dailyValues: autostockDailyValues,
		unit: '1/100',
		style: {
			borderWidth: 1.5,
			borderDash: [5, 1],
			format: 'percent',
		},
	};
	const bondPart: IndexData = {
		type: 'etc',
		code: 'BOND_PART',
		name: '채권 부분 수익률',
		color: CHART_COLORS.red,
		dailyValues: bondDailyValues,
		unit: '1/100',
		style: {
			borderWidth: 1.5,
			borderDash: [5, 1],
			format: 'percent',
		},
	};
	return { autostockPart, bondPart };
}

function isPhoneSized(screenSize: {width: number, height: number}) {
	return screenSize.width < 768;
}

class DailyMovingAverage {
	private firstDate: Date;
	private lastDate: Date;
	private average: number;

	constructor(firstDate: Date) {
		this.firstDate = firstDate;
		this.lastDate = firstDate;
		this.average = 0;
	}

	add(date: Date, value: number | null): {average: number, days: number} {
		const lastDays = (this.lastDate.getTime() - this.firstDate.getTime()) / (1000 * 60 * 60 * 24);
		const newDays = (date.getTime() - this.lastDate.getTime()) / (1000 * 60 * 60 * 24);
		const days = lastDays + newDays;

		if (value != null && days) {
			this.average = (this.average * lastDays + value * newDays) / (days);
		}
		this.lastDate = date;
		return { average: this.average, days };
	}
}
